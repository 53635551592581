import { DoubleArrow } from "@mui/icons-material";
import dayjs from "dayjs";
import useFormatDate from "hooks/useFormatDate";
import useTzDate from "hooks/useTzdate";

import moment from "moment/moment";
import { supplierService } from "service/SupplierService";
const supplierRepo = {

    getSuppliertable: async (pn, ps) => {
        try {
            const supplierData = await supplierService.getSupplierlist(pn, ps);
            console.log(supplierData)
            const mappedsupplierData = supplierData.map((x, i) => ({
                key: x.id,
                sno: i + 1,
                name: x.name,
                shortName: x.shortName,
                email: x.email,
                contact: x.contact,
                name1: x.name1,
                email1: x.email1,
                contact1: x.contact1,
                name2: x.name2,
                email2: x.email2,
                contact2: x.contact2,
                address: x.address,
                mailstatus: x.mailstatus,
                statusId: x.status,
                ssn: x.ssn,
                startDate: x.startDate ? dayjs(x.startDate).format('MM-DD-YYYY') : '',
                endDate: x.endDate ? dayjs(x.endDate).format('MM-DD-YYYY') : '',
                ein: x.ein,
                paymentTerms: x.paymentTerms,
                w9documentsData: x.supplierDocuments.filter((y) => y.type == 'W9'),
                msadocumentsData: x.supplierDocuments.filter((y) => y.type == 'MSA'),
                coidocumentsData: x.supplierDocuments.filter((y) => y.type == 'COI')
                // coiFileId:x?.supplierDocuments[0]?.coiFiles?.id,
                // coiFileName:x?.supplierDocuments[0]?.coiFiles?.name,
                // msaFileId:x?.supplierDocuments[0]?.msaFiles?.id,
                // msaFileName:x?.supplierDocuments[0]?.msaFiles?.name,
                // coiExpiryDate:dayjs(x?.supplierDocuments[0]?.expiryDate).format('MM-DD-YYYY'),
                // w9Year:dayjs(x?.supplierDocuments[0]?.year).format('YYYY')
            }));
            return mappedsupplierData;
        } catch (error) {
            console.error("Error fetching supplier data:", error);
            return [];
        }
    },

    getAllSuppliertable: async () => {
        try {
            const supplierData = await supplierService.getAllSupplierlist();
            console.log(supplierData)
            const mappedsupplierData = supplierData.map((x, i) => ({
                key: x.id,
                sno: i + 1,
                name: x.name,
                shortName: x.shortName,
                email: x.email,
                contact: x.contact,
                name1: x.name1,
                email1: x.email1,
                contact1: x.contact1,
                name2: x.name2,
                email2: x.email2,
                contact2: x.contact2,
                address: x.address,
                mailstatus: x.mailstatus,
                statusId: x.status?.id,
                status: x.status?.name,
                reasonForTermination: x.reasonForTermination,
                terminationDate: x.terminationDate ? dayjs(x.terminationDate).format('MM-DD-YYYY') : '',
                ssn: x.ssn,
                startDate: x.startDate ? dayjs(x.startDate).format('MM-DD-YYYY') : '',
                endDate: x.endDate ? dayjs(x.endDate).format('MM-DD-YYYY') : '',
                ein: x.ein,
                paymentTerms: x.paymentTerms,
                w9documentsData: x.supplierDocuments.filter((y) => y.type == 'W9'),
                msadocumentsData: x.supplierDocuments.filter((y) => y.type == 'MSA'),
                coidocumentsData: x.supplierDocuments.filter((y) => y.type == 'COI'),
                isPresent: x?.isPresent
                // coiFileId:x?.supplierDocuments[0]?.coiFiles?.id,
                // coiFileName:x?.supplierDocuments[0]?.coiFiles?.name,
                // msaFileId:x?.supplierDocuments[0]?.msaFiles?.id,
                // msaFileName:x?.supplierDocuments[0]?.msaFiles?.name,
                // coiExpiryDate:dayjs(x?.supplierDocuments[0]?.expiryDate).format('MM-DD-YYYY'),
                // w9Year:dayjs(x?.supplierDocuments[0]?.year).format('YYYY')
            }));
            return mappedsupplierData;
        } catch (error) {
            console.error("Error fetching supplier data:", error);
            return [];
        }
    },

    getTermsList: async () => {
        try {
            const termsList = await supplierService.getTerms();
            console.log(termsList)
            const mappedTerms = termsList.map((x, index) => ({
                key: x.id,
                name: x?.name
            }));
            return mappedTerms;
        } catch (error) {
            console.error("Error fetching Terms:", error);
            return [];
        }
    },


    getAllqualification: async () => {
        try {
            const qualificationList = await supplierService.getAllqualification();
            console.log(qualificationList)
            const mappedQualification = qualificationList.map((x, index) => ({
                key: x.id,
                name: x?.name
            }));
            return mappedQualification;
        } catch (error) {
            console.error("Error fetching Qualification:", error);
            return [];
        }
    },

    getsupplierbyId: async (id) => {
        try {
            const supplierData = await supplierService.getSuplierbyId(id);
            console.log(supplierData)
            const w9documentsData = [];
            const coidocumentsData = [];
            const msadocumentsData = [];
            if (supplierData?.supplierDocuments?.length > 0) {
                supplierData.supplierDocuments.forEach((document) => {
                    if (document.type === 'W9') {
                        w9documentsData.push({
                            key: document.id,
                            fileId: document.files.id,
                            fileName: document.files.name,
                            w9year: document.year

                        });
                    }
                    else if (document.type === 'MSA') {
                        msadocumentsData.push({
                            key: document.id,
                            fileId: document.files?.id,
                            fileName: document.files?.name,

                        });
                    }
                    else if (document.type === 'COI') {
                        coidocumentsData.push({
                            key: document.id,
                            fileId: document.files.id,
                            fileName: document.files.name,
                            coiExpiry: document.expiryDate

                        });
                    }
                });
            }
            const mappedsupplierData = [supplierData]?.map((x, i) => ({
                key: x.id,
                sno: i + 1,
                name: x.name ? x.name : '',
                shortName: x.shortName ? x.shortName : '',
                email: x.email ? x.email : '',
                contact: x.contact ? x.contact : '',
                name1: x.name1 ? x.name1 : '',
                email1: x.email1 ? x.email1 : '',
                contact1: x.contact1 ? x.contact1 : '',
                name2: x.name2 ? x.name2 : '',
                email2: x.email2 ? x.email2 : '',
                contact2: x.contact2 ? x.contact2 : '',
                address: x.address ? x.address : '',
                ssn: x.ssn ? x.ssn : '',
                startDate: x.startDate ? dayjs(x.startDate).format('MM-DD-YYYY') : '',
                endDate: x.endDate ? dayjs(x.endDate).format('MM-DD-YYYY') : '',
                ein: x.ein ? x.ein : '',
                paymentTerms: x.paymentTerms ? x.paymentTerms : '',
                w9documentsData: x.supplierDocuments.filter((y) => y.type == 'W9'),
                msadocumentsData: x.supplierDocuments.filter((y) => y.type == 'MSA'),
                coidocumentsData: x.supplierDocuments.filter((y) => y.type == 'COI'),
                w9documentsData,
                coidocumentsData,
                // coiFileId:x?.supplierDocuments[0]?.coiFiles?.id,
                // coiFileName:x?.supplierDocuments[0]?.coiFiles?.name,
                // msaFileId:x?.supplierDocuments[0]?.msaFiles?.id,
                // msaFileName:x?.supplierDocuments[0]?.msaFiles?.name,
                // coiExpiryDate:dayjs(x?.supplierDocuments[0]?.expiryDate).format('MM-DD-YYYY'),
                // w9Year:dayjs(x?.supplierDocuments[0]?.year).format('YYYY')
            }));


            return mappedsupplierData;
        } catch (error) {
            console.error("Error fetching supplier data:", error);
            return [];
        }
    },

    // postSupplier: async (form) => {
    //     try {
    //         const { success, message } = await supplierService.postSupplierService(form);
    //         return { success, message };
    //     } catch (error) {
    //         console.error("Error posting supplier data:", error);
    //         return error;
    //     }
    // },
    postSupplier: async (form) => {
        try {
            const { success, message } = await supplierService.postSupplierService(form);
            return { success, message };
        } catch (error) {
            console.error("Error posting supplier data:", error);
            return error;
        }
    },


    deleteSupplier: async (id) => {
        try {
            const { success, message } = await supplierService.deleteSupplier(id);
            return { success, message };
        } catch (error) {
            console.error("Error deleting supplier data:", error);
            return error;
        }
    },

    // notes
    getSuppliertableNotes: async (id) => {
        try {
            const supplierDatanotes = await supplierService.getSuppliernotes(id);
            console.log(supplierDatanotes)
            const mappedsupplierDatanotes = supplierDatanotes.map((x, i) => ({
                key: x.id,
                sno: i + 1,
                note: x.note,
                date: x.date
            }));
            return mappedsupplierDatanotes;
        } catch (error) {
            console.error("Error fetching supplier notes data:", error);
            return [];
        }
    },

    postSupplierNotes: async (form) => {
        try {
            const { success, message } = await supplierService.postSuppliernotes(form);
            return { success, message };
        } catch (error) {
            console.error("Error posting supplier notes data:", error);
            return error;
        }
    },
    deleteSupplierDocument: async (id) => {
        try {
            const { success, message } = await supplierService.deleteSupplierDocument(id);
            return { success, message };
        } catch (error) {
            console.error("Error deleting supplier notes data:", error);
            return error;
        }
    },
    deleteSupplierNotes: async (id) => {
        try {
            const { success, message } = await supplierService.deleteSuppliernotes(id);
            return { success, message };
        } catch (error) {
            console.error("Error deleting supplier notes data:", error);
            return error;
        }
    },
    postSupplierDocuments: async (form) => {
        try {
            const { success, message } = await supplierService.postSupplierDocuments(form);
            return { success, message };
        } catch (error) {
            console.error("Error posting supplier documents data:", error);
            return error;
        }
    },




    getW9coiReport: async (docType, supId) => {
        try {
            const w9Coireport = await supplierService.getW9coireport(docType, supId);
            console.log(w9Coireport)
            const mappedw9Coireport = w9Coireport?.map((x, i) => ({
                key: x.id,
                sno: i + 1,
                supplierName: x.supplierName,
                type: x.type,
                fileName: x.files?.name,
                fileId: x.files?.id,
                year: x.year,
                expiryDate: x.expiryDate ? useTzDate(x.expiryDate) : '',
            }));
            return mappedw9Coireport;
        } catch (error) {
            console.error("Error fetching supplier data:", error);
            return [];
        }
    },

    getSupInvreport: async (supId, status, date) => {
        try {
            const supInvreport = await supplierService.getSupplierinvoicereport(supId, status, date);
            console.log(supInvreport)
            const mappedsupInvreport = supInvreport?.map((x, i) => ({
                key: x.id,
                sno: i + 1,
                date: dayjs(x.date).format('MM-DD-YYYY'),
                fileName: x.files.name,
                supplierName: x.supplier.name,
                supplierId: x.supplier.id,
                invoiceAmount: x.invoiceAmount,
                fileId: x.files.id,
                invoiceHistory: x.invoiceHistory.map((z) => ({
                    createdAt: z.createdAt,
                    createdBy: z.createdBy,
                    key: z.id,
                    paymentReferenceId: z?.paymentReferenceId,
                    paymentDate: z?.paymentDate,
                    statusName: z.status.name,
                    statusId: z.status.id,
                    remark: z?.remark,
                }))
            }));
            return mappedsupInvreport;
        } catch (error) {
            console.error("Error fetching supplier data:", error);
            return [];
        }
    },
}

export { supplierRepo }